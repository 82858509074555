import { createActionCreator, createReducer } from 'deox';
import { Dispatch } from 'redux';
import * as partnersService from '../../services/partners';
import { withErrorDispatch } from './withErrorDispatch';
import { actions as messageActions } from './messages';
import { Partner } from '../../models/misc';

export type State = Partner[];

const defaultState: State = [];

const fetchAllPartnersSuccess = createActionCreator(
  '@@ADMIN/PARTNERS/FETCH_ALL_SUCCESS',
  (resolve) => (partners: Partner[]) => resolve(partners)
);

const fetchAllPartners = () =>
  withErrorDispatch(
    partnersService.getAllPartners(),
    (data: Partner[]) => fetchAllPartnersSuccess(data),
    'Error fetching partners'
  );

// Add Partner
const addPartnerSuccess = createActionCreator(
  '@@ADMIN/PARTNERS/ADD_SUCCESS',
  (resolve) => (partner: Partner) => resolve(partner)
);

const addPartner = (partner: Omit<Partner, 'id'>) =>
  withErrorDispatch(
    partnersService.addPartner(partner),
    (data: Partner) => addPartnerSuccess(data),
    'Error adding partner'
  );

// EDIT Partner
const editPartnerSuccess = createActionCreator(
  '@@ADMIN/PARTNERS/EDIT_SUCCESS',
  (resolve) => (partner: Partner) => resolve(partner)
);

const editPartner = (partner: Partner) =>
  withErrorDispatch(
    partnersService.editPartner(partner),
    (data: Partner) => editPartnerSuccess(data),
    'Error editing partner'
  );



// Delete Partner
const deletePartnerSuccess = createActionCreator(
  '@@ADMIN/PARTNERS/DELETE_SUCCESS',
  (resolve) => (id: number) => resolve(id)
);

const deletePartner = (id: number) =>
  withErrorDispatch(
    partnersService.deletePartner(id),
    () => deletePartnerSuccess(id),
    'Error deleting partner'
  );

const exportPartnerTemplate = (partnerName: string) => async (dispatch: Dispatch) => {
  try {
    const data = await partnersService.exportPartnerTemplate(partnerName);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${partnerName}_Template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.log('error downloading template')
  }
};

// Reducer

const reducer = createReducer(defaultState, (handleAction) => [
  handleAction(fetchAllPartnersSuccess, (state, action) => action.payload),
  handleAction(addPartnerSuccess, (state, action) => [...state, action.payload]),
  handleAction(editPartnerSuccess, (state, action) =>
    state.map((p) => (p.id === action.payload.id ? action.payload : p))),
  handleAction(deletePartnerSuccess, (state, action) =>
    state.filter((partner) => partner.id !== action.payload)
  ),
]);

const actions = {
  fetchAllPartners,
  addPartner,
  deletePartner,
  editPartner,
  exportPartnerTemplate,
};

export { actions, reducer };